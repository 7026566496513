import React, { CSSProperties, useEffect, useState } from "react";

import { icons, IconTypes } from "./icons";
interface IconProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  icon: IconTypes;
  width?: string | number;
  height?: string | number;
  onClick?: (event: React.MouseEvent<HTMLImageElement>) => void;
  style?: CSSProperties;
  className?: string | undefined;
  title?: string;
}

export const Icon = React.memo((props: IconProps) => {
  const {
    icon,
    width = 24,
    height = 24,
    onClick,
    style,
    className,
    title,
  } = props;

  const [img, setImg] = useState<string>();

  const defalutStyle: CSSProperties = {
    cursor: onClick ? "pointer" : "inherit",
    userSelect: "none",
  };

  useEffect(() => {
    icons[icon].then((module) => setImg(module.default));
  }, [icon]);

  return (
    <img
      style={style || defalutStyle}
      width={width}
      height={height}
      src={img}
      className={className}
      onClick={onClick}
      title={title}
    />
  );
});
