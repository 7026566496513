import { TimbreOption } from "@/types/dept-tutorials";
import { atomWithStorage } from "@/utils/recoil-utils";

export interface ITranslationVideoParams {
  source_language: number;
  target_language: number;
  source_language_text: string;
  target_language_text: string;
  bgm: number;
  subtitles: number;
  voices: TimbreOption;
}

export const translationVideoState = atomWithStorage<ITranslationVideoParams>({
  key: "translationVideoState",
  storageKey: "translationVideoState",
  default: undefined,
});
