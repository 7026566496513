import { useMount, useRequest } from "ahooks";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

import { Env } from "@/config/env";
import { TOKEN_EXPIRE, TOKEN_IN_COOKIE } from "@/libs/constants";
import { isUnreadMessageState, staffState } from "@/models";
import { authLogoutReq } from "@/services/api/api-auth";
import { getUnreadNotificationsApi } from "@/services/api/notifications";
import { localRemove, localSet } from "@/utils/storage";

export const useUserModalStore = () => {
  const navigator = useNavigate();

  const { pathname } = useLocation();

  const [staff, setStaff] = useRecoilState(staffState);

  const [isUnreadMessage, setIsUnreadMessage] =
    useRecoilState(isUnreadMessageState);

  const token = Cookies.get(TOKEN_IN_COOKIE);

  const getUnreadNotificationsRequest = useRequest(getUnreadNotificationsApi, {
    manual: true,
    onSuccess: (res) => {
      const { meta } = res;

      setIsUnreadMessage(meta.total > 0);
    },
  });

  const handleLogout = () => {
    authLogoutReq().then(() => {
      localRemove(TOKEN_EXPIRE);
      Cookies.remove(TOKEN_IN_COOKIE, {
        domain: Env.DOMAIN,
      });
      setStaff(null);
      navigator("/login");
    });
  };

  // 监听路由变化，记录参数
  useEffect(() => {
    if (pathname !== "/login") {
      localSet("search", window.location.search);
    }
  }, [pathname]);

  useMount(() => {
    token && getUnreadNotificationsRequest.run();
  });

  return {
    staff,
    isUnreadMessage,
    handleLogout,
  };
};
